import { useState, useEffect} from "react"
import "./footer.css"

import DisabledLinksList from "./Disabel.jsx"
import EnableLinksList from "./Enable.jsx"

import bg from "../../assets/image/bg-footer.png"
import bgsm from "../../assets/image/bg-footer-sm.png"
import { isDotIr } from '@/utils/isDotIr';

import social1 from "../../assets/svg/social (1).svg"
import social2 from "../../assets/svg/social (2).svg"
import social3 from "../../assets/svg/social (3).svg"
import social4 from "../../assets/svg/social (4).svg"
import social5 from "../../assets/svg/social (5).svg"
import social6 from "../../assets/svg/social (6).svg"

const Footer = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <footer className="footer  relative lg:h-[150px] h-[250px] flex items-end bg-transparent">
      {!isMobile && <img src={bg} className="w-full h-[150px] absolute inset-0 bottom-0" />}
      {isMobile && <img src={bgsm} className="w-full h-[250px] absolute inset-0 bottom-0" />}

      <div className="relative z-[1] inset-0 lg:px-14 max-w-[1200px] w-full mx-auto flex items-end justify-between max-lg:flex-col max-lg:items-center max-lg:pt-14">
        <ul style={{ direction: 'ltr' }} className="flex gap-x-3 text-white ps-0 max-lg:order-3">
          <li className="hover:scale-110 transition">
            <a href="https://instagram.com/hosein_aura/" target="_blank">
              <img src={social1} width={28} />
            </a>
          </li>
          <li className="hover:scale-110 transition">
            <a href="https://open.spotify.com/show/2HIXQBtFdNg2QfjoFoIChK?si=VQ-8D0xYQC6vULDyX_31Iw" target="_blank">
              <img src={social2} width={28} />
            </a>
          </li>
          <li className="hover:scale-110 transition">
            <a href="https://podcasts.apple.com/us/podcast/%D8%A7%DB%8C%D9%86-%D9%86%D9%82%D8%B7%D9%87/id1607369690" target="_blank">
              <img src={social5} width={28} />
            </a>
          </li>
          <li className="hover:scale-110 transition">
            <a href="https://castbox.fm/va/5175428" target="_blank">
              <img src={social6} width={25} />
            </a>
          </li>
          <li className="hover:scale-110 transition">
            <a href="https://t.me/hoseinmind" target="_blank">
              <img src={social4} width={28} />
            </a>
          </li>
          <li className="hover:scale-110 transition">
            <a href="https://www.youtube.com/channel/UCxL5awDwnPpPmcYos4zZSSA" target="_blank">
              <img src={social3} width={28} />
            </a>
          </li>
        </ul>

        <p className="text-white text-[16px] font-medium lg:pb-3 max-lg:order-4 max-lg:bg-[#131313] max-lg:py-1 text-center max-lg:w-full m-0">
          <span className="text-[17px]">© </span>
          <span className="text-[17px]">تمامی حقوق مادی و معنوی برای</span>
          {" "}
          <span className="text-[17px]">این نقطه </span>
          <span className="text-[17px]">محفوظ است.</span>
        </p>
        {isDotIr ? <EnableLinksList></EnableLinksList> : <DisabledLinksList></DisabledLinksList>}

      </div>
    </footer>
  )
}

export default Footer;