// src/utils/cacheBuster.ts
import { useEffect } from 'react';
import { BUILD_TIME } from './buildInfo';

const VERSION_KEY = 'app_version';

export const clearCacheData = () => {
    // Clear localStorage
    localStorage.clear();

    // Clear sessionStorage
    sessionStorage.clear();

    // Clear all caches
    if (window.caches) {
        caches.keys().then((names) => {
            names.forEach((name) => {
                caches.delete(name);
            });
        });
    }

    // Store new version
    localStorage.setItem(VERSION_KEY, BUILD_TIME);
};

export const useCacheBuster = () => {
    useEffect(() => {
        const currentVersion = localStorage.getItem(VERSION_KEY);

        if (currentVersion !== BUILD_TIME) {
            clearCacheData();

            // Reload the page to ensure clean state
            // Add a small delay to ensure localStorage is updated
            setTimeout(() => {
                window.location.reload();
            }, 1000 * 1);
        }
    }, []);
};