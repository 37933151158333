import React, { useEffect, useState, useRef } from 'react';
import Main_Nav from "@/components/main-nav/Main_Nav";
import Footer from "@/components/footer/Footer";
import axiosInstance from '@/utils/axiosInstance';
import { LoadingScreen, ErrorScreen } from '@/metronic-dashboard/layout/components/Loading_Error';
import { convertNumsToFa } from '@/utils/numLangChanger';

import light from '@/assets/image/light6.png';
import bookIcon from '@/assets/svg/book.svg';
import arrowIcon from '@/assets/svg/Arrow - Left.svg';
import arrow2Icon from '@/assets/svg/Arrow - Right.svg';

const Listening = () => {
    const [nav_DropDown, setNav_DropDown] = useState(false);
    const close_Popup = e => {
        const dropdownElement = document.querySelector('.nav-dropdown');
        if (nav_DropDown && dropdownElement && !dropdownElement.contains(e.target)) setNav_DropDown(false);
    }

    const [error, setError] = useState(false);
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);  // Initialize as true
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await axiosInstance.get('/website/listen_list', {
                    params: { page: currentPage }
                });

                // Clear existing data before setting new data
                setData(null);

                // Small timeout to ensure state is cleared before setting new data
                setTimeout(() => {
                    setData(response.data);
                    setCurrentPage(response.data.items.current_page);
                    setTotalPages(response.data.items.last_page);
                }, 0);

                window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
            } catch (error) {
                setError(true);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [currentPage]);

    useEffect(() => {
        convertNumsToFa();
    }, [data, currentPage]);


    const theEl = useRef(null);
    const [desFromTop, setDesFromTop] = useState();

    const handleDistanceFromTop = () => {
        const element = theEl.current;
        if (element) {
            const rect = element.getBoundingClientRect();
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            const offsetTop = rect.top + scrollTop;
            setDesFromTop(offsetTop);
        }
    }

    useEffect(() => {
        handleDistanceFromTop();
    }, [data]);

    // Proper event listener cleanup
    useEffect(() => {
        window.addEventListener('resize', handleDistanceFromTop);
        return () => window.removeEventListener('resize', handleDistanceFromTop);
    }, []);

    if (error) return <ErrorScreen />;

    return (
        <div className='bg-white text-[#212121]' onClick={close_Popup}>
            <Main_Nav nav_DropDown={nav_DropDown} setNav_DropDown={setNav_DropDown} />

            <LoadingScreen loading={loading} />

            <section className="relative pt-40 max-lg:pt-32 overflow-hidden lg:mb-8 max-lg:mb-20">
                <div
                    style={{
                        transform: 'skew(0,4deg)',
                        background: 'linear-gradient(180deg, #1C4451, #1D282D 150%)',
                        '--des-from-top': `${desFromTop}px`
                    } as React.CSSProperties}
                    className='bg-box-color absolute inset-0 lg:mt-[400px] mb-[170px] max-lg:mb-[150px] rounded-[5vw] z-0'
                ></div>

                <div className='my-container relative lg:mb-8'>
                    {data && (
                        <>
                            <div className='flex justify-between items-center lg:border-b border-[#eee] lg:pb-1 mb-1.5 max-lg:justify-center'>
                                <h3 className='text-[#212121] font-bold max-lg:text-[30px] flex gap-3'>
                                    <img src={bookIcon} className='max-lg:hidden' alt="Book Icon" />
                                    {data.category.title}
                                </h3>
                            </div>
                            <div className='text-[#212121] text-[21px] font-semibold max-lg:text-[20px] max-lg:text-center mb-9 text-justify'>
                                {data.category.list_title}
                            </div>
                        </>
                    )}
                </div>

                <div className="my-container relative">
                    {data && data.items.data.length > 3 && (
                        <>
                            <div className='max-lg:hidden absolute z-0 left-[-240px] top-20 w-[400px] h-[1000px]'>
                                <img src={light} className='mx-auto' alt="Decorative light" />
                                <div className='absolute inset-0 top-[300px] scale-y-[7]' style={{ background: 'radial-gradient(circle, #00FFD111 0%, transparent 40%)' }}></div>
                            </div>
                            <div className='max-lg:hidden absolute z-0 right-[-240px] bottom-0 w-[400px] h-[1000px]'>
                                <img src={light} className='mx-auto' alt="Decorative light" />
                                <div className='absolute inset-0 bottom-[-100px] scale-y-[7]' style={{ background: 'radial-gradient(circle, #00FFD111 0%, transparent 15%)' }}></div>
                            </div>
                        </>
                    )}

                    <div ref={theEl} className='relative flex flex-col gap-9'>
                        <div className="flex gap-x-8 gap-y-10 justify-center flex-wrap text-white">
                            {data?.items.data.map((item, i) => (
                                <div key={i} className='flex flex-col items-center gap-3 w-[350px] max-w-full rounded-3xl p-7' style={{ background: 'linear-gradient(150deg, #3A606F, #0B1F26)', border: '1px solid #28424B' }}>
                                    <div className="flex justify-center w-full items-center gap-4">
                                        <p className='text-[18px] -mt-2'>{item.tags}</p>
                                    </div>
                                    <div className='w-[270px] h-[270px] rounded-full relative' style={{ background: 'linear-gradient(130deg, #41464B, #1A1B1F)', boxShadow: '12px 14px 15px -2px #00000030' }}>
                                        <div className='absolute inset-1 rounded-full' style={{ background: 'linear-gradient(130deg, #0F1314, #121617)' }}>
                                            <img src={item.image_media[0].src} className='absolute w-[246px] h-[246px] inset-2 object-cover rounded-full' alt={item.title} />
                                        </div>
                                    </div>
                                    <h3 className='text-[26px]'>{item.title_fa}</h3>
                                    <h4 className='text-[26px] font-medium -mt-6'>{item.title}</h4>
                                    <div className="flex flex-col w-full -mt-1">
                                        <div className="flex justify-between w-full items-center gap-4">
                                            <p className='text-[18px] font-semibold'>کاری از:</p>
                                            <p className='text-[18px] font-medium'>{item.author_fa}</p>
                                        </div>
                                        <div className="flex justify-between w-full items-center gap-4">
                                            <p className='text-[18px] font-semibold'>آلبوم:</p>
                                            <p className='text-[18px] font-medium'>{item.from_album}</p>
                                        </div>
                                        <div className="flex justify-between w-full items-center gap-4">
                                            <p className='text-[18px] font-semibold'>مدت زمان:</p>
                                            <p className='text-[18px] font-medium'>{item.audio_media[0].duration}</p>
                                        </div>
                                    </div>
                                    <audio controls className='h-[40px] w-full rounded-full bg-[#00c3ff]' style={{ border: '1px solid #aaa' }}>
                                        <source src={item.audio_media[0].urls[0].url} type="audio/mpeg" />
                                    </audio>
                                    <p className='text-[18px] text-justify grow flex items-center'>{item.full_info}</p>
                                </div>
                            ))}
                        </div>

                        {data?.items.last_page > 1 && (
                            <div className='flex justify-between items-center'>
                                <div className='relative'>
                                    {loading && (
                                        <div className='absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2'>
                                            <span className="spinner-border spinner-border-sm align-middle"></span>
                                        </div>
                                    )}
                                    <div className={`flex gap-3 items-center ${loading && 'opacity-40 pointer-events-none'}`}>
                                        <button
                                            onClick={() => setCurrentPage(prev => prev - 1)}
                                            disabled={currentPage === 1 || loading}
                                            className='h-10 w-10 rounded flex items-center justify-center hover:bg-[#f8f8f8] transition'
                                            style={{ border: '1px solid var(--orange-primary)' }}
                                        >
                                            <img src={arrow2Icon} width={23} alt="Previous page" />
                                        </button>
                                        <button
                                            onClick={() => setCurrentPage(prev => prev + 1)}
                                            disabled={currentPage === totalPages || loading}
                                            className='font-semibold text-[24px] max-lg:text-[20px] h-10 px-3 text-white rounded flex items-center justify-center gap-2 bg-[--orange-primary] hover:bg-[--orange-primary-hover] transition'
                                        >
                                            صفحه بعدی
                                            <img src={arrowIcon} alt="Next page" />
                                        </button>
                                    </div>
                                </div>
                                <div className='flex gap-[.4rem]'>
                                    <span className='font-semibold text-[23px] max-lg:text-[20px] text-[#212121]'>صفحه</span>
                                    <span className='font-semibold text-[23px] max-lg:text-[20px] text-[#212121]'>{currentPage}</span>
                                    <span className='font-semibold text-[23px] max-lg:text-[20px] text-[#212121]'>از</span>
                                    <span className='font-semibold text-[23px] max-lg:text-[20px] text-[#212121]'>{totalPages}</span>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </section>

            <Footer />
        </div>
    );
}

export default Listening;