const config = {
  com: {
    isDotIr: false,
    apiUrl: import.meta.env.VITE_API_URL,
    environment: import.meta.env.VITE_ENV,
    featureFlags: {
      enableNewFeature: false,
      enableBetaFeatures: false
    },
  },
  ir: {
    isDotIr: true,
    apiUrl: import.meta.env.VITE_API_URL,
    environment: import.meta.env.VITE_ENV,
    featureFlags: {
      enableNewFeature: false,
      enableBetaFeatures: false
    },
  },
};

// Get environment from Vite environment variable
const environment = import.meta.env.VITE_IS_DOT_IR || 'com';

// Debug logging
if (import.meta.env.DEV) {
  console.log('Current environment:', environment);
  console.log('Selected config:', config[environment]);
}

export const isDotIr = config[environment]?.isDotIr || false;
export default config[environment] || config.com;