import React, { useEffect } from "react";

interface TextToSpeakProps {
  textToSpeak: string | number;
}

const TextToSpeak: React.FC<TextToSpeakProps> = ({ textToSpeak }) => {
  const convertNumberToWords = (number: string): string => {
    const numberWords: Record<string, string> = {
      0: "zero",
      1: "one",
      2: "two",
      3: "three",
      4: "four",
      5: "five",
      6: "six",
      7: "seven",
      8: "eight",
      9: "nine",
    };

    return number
      .split("")
      .map((digit) => numberWords[digit])
      .join(" ");
  };

  useEffect(() => {
    const speakText = (text: string) => {
      if ("speechSynthesis" in window) {
        const utterance = new SpeechSynthesisUtterance(text);
        window.speechSynthesis.speak(utterance);
      } else {
        console.error("SpeechSynthesis is not supported in this browser.");
      }
    };

    const intervalId = setInterval(() => {
      const words = convertNumberToWords(textToSpeak.toString());
      speakText(words);
    }, 1200000);

    return () => clearInterval(intervalId);
  }, [textToSpeak]);

  return null;
};

export default TextToSpeak;
