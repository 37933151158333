import React, { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import ZoomMtgEmbedded from "@zoom/meetingsdk/embedded";
import Watermark from "../waterMark/waterMark";
import Texttospeak from "../textToSpeak/textToSpeak.js";
import "./style.css";
import { isDotIr } from "@/utils/isDotIr";
import Cookies from "js-cookie";
import axiosInstance from "@/utils/axiosInstance";

export const ZoomMeeting = () => {
  const [searchParams] = useSearchParams();
  const containerRef = useRef<HTMLDivElement>(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);
  const [userId, setuserId] = useState(null);
  const [password, setPassword] = useState('122548');
  const [userName, setUserName] = useState(null);
  const [userEmail, setUserEmail] = useState(null);

  // Get parameters from URL
  const meetingNumber = searchParams.get('meetingNumber');
  const signature = searchParams.get('signature');

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1200);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const fetchUserData = async () => {
    try {
      const response = await axiosInstance.get(`/profile`, {
        headers: {
          Authorization: `Bearer ${Cookies.get(
            isDotIr ? "innoghteh_access_token" : "innoghteh_access_token_com"
          )}`,
        },
      });

      if (response.status === 200) {
        setuserId(response.data.id);
        setUserName(response.data.full_name);
        setUserEmail(response.data.email);
      } else {
        window.location.href = '/auth/login';
      }
    } catch (error) {
      console.log('Error occurred - redirecting to login page');
      window.location.href = '/auth/login';
    }
  };

  useEffect(() => {
    fetchUserData();
    let timer = 300;

    const serverCheckInterval = setInterval(() => {
      fetchUserData();
      timer = 300;
    }, 300000);

    const timerInterval = setInterval(() => {
      timer -= 30;
      console.log(`Time until next server check: ${timer} seconds`);
    }, 30000);

    return () => {
      clearInterval(serverCheckInterval);
      clearInterval(timerInterval);
    };
  }, []);

  useEffect(() => {
    if (!signature) {
      console.error('Signature is required for joining the meeting');
      return;
    }

    try {
      const client = ZoomMtgEmbedded.createClient();

      const meetingSDKElement = document.getElementById("meetingSDKElement");
      const meetingSDKChatElement = document.getElementById("meetingSDKChatElement");
      const meetingSDKparticipantsElement = document.getElementById("meetingSDKparticipantsElement");
      const meetingSDKsettingElement = document.getElementById("meetingSDKsettingElement");
      const meetingSDKactiveAppsElement = document.getElementById("meetingSDKactiveAppsElement");
      const meetingSDKmeetingElement = document.getElementById("meetingSDKmeetingElement");

      if (!meetingSDKElement || !meetingSDKChatElement) {
        console.error("Required DOM elements not found");
        return;
      }

      const width = isMobile ? 720 : 720;
      const height = isMobile ? 580 : 830;

      // Function to ensure element stays within viewport
      const keepInViewport = ({ x, y, reference, floating }) => {
        const viewportWidth = window.innerWidth;
        const viewportHeight = window.innerHeight;
        const floatingWidth = floating.width;
        const floatingHeight = floating.height;
        const referenceRect = reference.getBoundingClientRect();

        // Calculate bounds relative to reference element
        x = Math.min(Math.max(0, x), viewportWidth - floatingWidth);
        y = Math.min(Math.max(0, y), viewportHeight - floatingHeight);

        // Adjust position to stay near reference
        if (x + floatingWidth > viewportWidth) {
          x = referenceRect.right - floatingWidth;
        }
        if (y + floatingHeight > viewportHeight) {
          y = referenceRect.bottom - floatingHeight;
        }

        return { x, y };
      };

      client.init({
        zoomAppRoot: meetingSDKElement,
        language: "en-US",
        customize: {
          meeting: {
            popper: {
              disableDraggable: false,
              anchorElement: meetingSDKmeetingElement,
              placement: "top",
              modifiers: [
                {
                  name: 'preventOverflow',
                  options: {
                    boundary: 'viewport',
                    padding: 8
                  }
                },
                {
                  name: 'flip',
                  options: {
                    fallbackPlacements: ['bottom', 'right', 'left'],
                    padding: 8
                  }
                },
                {
                  name: 'computeStyles',
                  options: {
                    gpuAcceleration: false,
                    adaptive: true
                  }
                },
                {
                  name: 'custom',
                  phase: 'beforeWrite',
                  fn: ({ state }) => {
                    const { x, y } = keepInViewport({
                      x: state.x,
                      y: state.y,
                      reference: state.rects.reference,
                      floating: state.rects.floating
                    });
                    state.x = x;
                    state.y = y;
                  }
                }
              ]
            },
          },
          activeApps: {
            popper: {
              disableDraggable: false,
              anchorElement: meetingSDKactiveAppsElement,
              placement: "top-end",
              modifiers: [
                {
                  name: 'preventOverflow',
                  options: {
                    boundary: 'viewport',
                    padding: 8
                  }
                }
              ]
            },
          },
          setting: {
            popper: {
              disableDraggable: false,
              anchorElement: meetingSDKsettingElement,
              placement: "top-start",
              modifiers: [
                {
                  name: 'preventOverflow',
                  options: {
                    boundary: 'viewport',
                    padding: 8
                  }
                }
              ]
            },
          },
          participants: {
            popper: {
              disableDraggable: false,
              anchorElement: meetingSDKparticipantsElement,
              placement: "bottom-start",
              modifiers: [
                {
                  name: 'preventOverflow',
                  options: {
                    boundary: 'viewport',
                    padding: 8
                  }
                }
              ]
            },
          },
          chat: {
            popper: {
              disableDraggable: false,
              anchorElement: meetingSDKChatElement,
              placement: "bottom-end",
              modifiers: [
                {
                  name: 'preventOverflow',
                  options: {
                    boundary: 'viewport',
                    padding: 8
                  }
                }
              ]
            },
          },
          video: {
            isResizable: false,
            viewSizes: {
              default: {
                width: width,
                height: height,
              },
            },
          },
        },
      });

      client
        .join({
          sdkKey: import.meta.env.VITE_ZOOM_SDK_KEY,
          signature: signature,
          meetingNumber: meetingNumber,
          password: password,
          userName: userName,
          userEmail: userEmail,
        })
        .catch((error) => {
          console.error("Failed to join meeting:", error);
        });
    } catch (error) {
      console.error("Error initializing Zoom client:", error);
    }
  }, [isMobile, meetingNumber, password, userName, userEmail, signature]);

  return (
    <div className="zoom-container">
      <div id="meetingSDKChatElement"></div>
      <div id="meetingSDKparticipantsElement"></div>
      <div id="meetingSDKsettingElement"></div>
      <div id="meetingSDKactiveAppsElement"></div>
      <div id="meetingSDKmeetingElement"></div>
      <div ref={containerRef} id="meetingSDKElement"></div>
      <Watermark text={userId} containerRef={containerRef} />
      <Texttospeak textToSpeak={userId} />
    </div>
  );
};

export default ZoomMeeting;